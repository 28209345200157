@import 'partials/variables';
@import 'partials/breakpoints';
@import 'partials/shadows';

@mixin responsive {
  position: relative;

  .map-selectors-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;

    position: absolute;

    top: 0px;
    left: unset;
    right: 1em;

    .map-selector {
      user-select: none;

      min-width: 150px;

      z-index: 10;
      &:first-child {
        z-index: 11;
      }
      .ipcc-select__label {
        color: $primary-color;
        text-shadow: 0.5px 0.5px 0.5px white;
        font-size: 0.8em;
        font-weight: 400;
        padding-bottom: 0px;
      }
    }
  }

  .ipcc-map__caption {
    position: fixed;
    bottom: 0;
    width: 100%;

    .map__caption-wrapper {
      font-size: 12px;
      margin: 4px;
      white-space: nowrap;
      text-overflow: unset;

      overflow-x: scroll;
    }
  }

  .map__container__options {
    top: -42px;
    width: 100%;

    .option__dropdown-box {
      .dropdown__body {
        height: calc(100vh - 70px - 41px);
        overflow-x: hidden;
        * {
          overflow-x: hidden;
        }

        .options__body__list-container {
          width: calc(100% - 3em);
          .radio-list-selector {
            .radio-list-selector__body {
              .radio-list-selector__radio {
                label {
                  font-size: 0.9em;
                }
              }
            }
          }
        }

        .variable-group-column {
          width: 100%;
        }

        .option__dropdown-content {
          overflow-y: auto !important;
          .options__body {
            padding: 0px;
          }
        }
      }
    }
  }

  .ipcc-controls__container {
    right: 0.5em;
    left: unset;
  }

  .ipcc-json-legend-container {
    position: fixed;
    bottom: 25px;
  }
}

@include respond-to(small) {
  .map__container {
    @include responsive();
  }
}

@include respond-to(large) {
  .map__container.mobile {
    @include responsive();
  }
}
