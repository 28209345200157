@import 'partials/variables';
@import 'partials/breakpoints';
@import 'partials/shadows';

.ipcc-json-legend-container {
  position: absolute;
  bottom: 0px;
  left: 10px;
  z-index: 1000;
  max-height: 100%;

  .legend-wrapper {
    transition: transform 300ms ease-in-out;
    transform-origin: bottom center;
    width: auto;
  }

  .legend-units {
    transition: opacity 300ms ease-in-out;

    font-size: 0.8em;
    padding-bottom: 1em;
    color: $primary-color;
    text-shadow: 1px 1px 1px white;
    user-select: none;

    z-index: 1001;

    .edit-legend-icon {
      position: absolute;

      width: 20px;
      height: 20px;

      top: -25px;
      left: -10px;

      margin-left: 1em;

      cursor: pointer;

      path {
        fill: $primary-color;
      }

      &:hover,
      &:focus {
        path {
          fill: #666;
        }
      }
    }
  }

  &.open {
    .legend-wrapper {
      transform: scaleY(1);
    }
    .legend-units {
      opacity: 1;
    }
  }

  &.close {
    .legend-wrapper {
      transform: scaleY(0);
    }
    .legend-units {
      opacity: 0;
    }
    .hatching-segments-container {
      height: 0px;
    }
  }

  .resize-legend {
    z-index: 1001;
    background-color: transparent;
    border-radius: 5px 5px 0px 0px;
    cursor: pointer;

    svg {
      transition: transform 300ms ease-in-out;
      &:focus {
        path {
          fill: darken($color: $primary-color, $amount: 30);
        }
      }
    }

    &.close {
      svg {
        transform: rotate(180deg);
        border-radius: 0px 0px 5px 5px;
      }
    }

    path {
      fill: $primary-color;
      transition: fill 150ms linear;
    }

    &:hover {
      path {
        fill: darken($color: $primary-color, $amount: 30);
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;

    .axis-svg {
      position: absolute;
      overflow: visible;
      left: 15px;

      .domain,
      .tick > line {
        display: none;
      }

      .tick > text {
        color: $primary-color;
        font-size: 12px;
        font-weight: lighter;
        text-shadow: 0px 1px 1px white;
      }
    }

    .legend-item__container {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;

      position: relative;
      &.active {
        .legend-item {
          z-index: 100;
          transform: scale(1.5);
          @include shadow-3dp();
        }
      }
      .legend-item {
        box-sizing: border-box;
        width: 20px;
        transition: transform 100ms ease-in-out, box-shadow 100ms ease-in-out;
        overflow: hidden;
        &:hover,
        &.active,
        &:focus {
          z-index: 101;
          transform: scale(1.5);
          @include shadow-3dp();
        }
      }

      .max-min__container {
        opacity: 0;

        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        left: 30px;
        color: $primary-color;
        text-overflow: clip;
        transform: scale(1.5);
        z-index: 100;
        transition: opacity 200ms linear;

        pointer-events: none;

        .max,
        .min {
          border: none;
          border-right: none;
          background: $primary-color;
          padding: 6px 3px 3px 6px;
          height: 16px;
          // width: 34px;
          width: 50px;
          position: absolute;
          transform: scale(0.66667);
          color: white;
          right: -50px;
          font-weight: bold;
          text-align: center;
          &:after {
            content: '';
            position: absolute;
            top: -5px;
            left: -17px;
            display: inline-block;
            border-top: 18px solid transparent;
            border-bottom: 18px solid transparent;
            border-right: 18px solid $primary-color;
          }
        }

        .max {
          top: -12px;
        }
        .min {
          bottom: -11px;
        }

        &.small {
          .max,
          .min {
            border: none;
            border-right: none;
            background: $primary-color;
            padding: 6px 3px 3px 6px;
            height: 16px;
            width: 50px;
            position: absolute;
            transform: scale(0.66667);
            color: white;
            right: -60px;
            font-weight: bold;
            z-index: 100;
          }
          .max {
            &:after {
              content: '';
              position: absolute;
              top: -1.5px;
              left: -17px;
              display: inline-block;
              border-top: 26px solid transparent;
              border-bottom: 0px solid transparent;
              border-right: 18px solid $primary-color;
            }
            top: -20px;
          }
          .min {
            bottom: -22px;
            &:after {
              content: '';
              position: absolute;
              top: 0px;
              left: -17px;
              display: inline-block;
              border-top: 0px solid transparent;
              border-bottom: 26px solid transparent;
              border-right: 18px solid $primary-color;
            }
          }
        }

        &.top:not(:hover) {
          opacity: 1;
          .min {
            opacity: 0;
          }
        }
        &.bottom:not(:hover) {
          opacity: 1;
          .max {
            opacity: 0;
          }
        }
        &.none:not(:hover) {
          opacity: 0;
        }
      }

      .legend-item:hover + .max-min__container {
        opacity: 1;
        .min,
        .max {
          opacity: 1;
        }
      }

      .mean__tooltip {
        display: flex;
        user-select: none;

        padding-left: 6px;

        color: $primary-color;

        visibility: hidden;

        font-size: 14px;

        align-items: flex-end;

        transform: translateY(-8px);

        text-shadow: 1px 1px 1px white;

        &.fixed {
          visibility: visible;
          user-select: none;
          cursor: default;
        }

        &.first {
          transform: translateY(-8px);
          align-items: flex-start;
        }

        &.last {
          transform: translateY(8px);
        }
      }
    }

    .color-indicator {
      position: absolute;

      width: 26px;
      height: 4px;

      left: -3px;

      background-color: $primary-color;

      z-index: 102;

      transition: bottom 100ms linear;
    }
  }

  &.mirroring.left {
    .legend-units {
      .edit-legend-icon {
        left: 5px;
      }
    }
  }
}
