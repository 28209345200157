@import 'partials/variables';
@import 'partials/shadows';

.ipcc-select {
  display: flex;
  flex-direction: column;

  z-index: 10001;

  .ipcc-select__label {
    padding: 0.5em 1em 0.5em 0em;
    color: $primary-color-accent;
    font-weight: lighter;
  }

  .ipcc-select__select-native {
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 25px;
    font-size: 16px;
    background-color: $primary-color;
    color: white;
    transition: border-radius 0.15s linear;
    &:active {
      border-radius: 5px 5px 0px 0px;
    }
  }

  .select {
    height: 24px;

    cursor: pointer;

    &:focus-within {
      box-shadow: 0px 0px 5px 5px lighten($primary-color, 30);
    }
    .ipcc-react-select__control {
      height: 24px;
      min-height: 24px;

      border-radius: 4px;
      border-color: $primary-color;
      font-weight: normal;
      border-width: 1px;

      background-color: $primary-color;

      color: $primary-color-accent;
      cursor: pointer;

      .ipcc-react-select__value-container {
        height: 24px;

        font-size: 1em;
        line-height: 1em;
        color: $primary-color-accent;

        .ipcc-react-select__placeholder {
          color: $primary-color-accent;
        }

        .ipcc-react-select__single-value {
          color: $primary-color-accent;
        }
      }

      .ipcc-react-select__indicators {
        height: 24px;

        svg {
          path {
            fill: $primary-color-accent;
          }
        }

        transition: transform 150ms linear;
        .ipcc-react-select__indicator-separator {
          display: none;
        }
      }
      &.ipcc-react-select__control--is-focused {
        border-color: $primary-color;
        box-shadow: none;
      }

      &.ipcc-react-select__control--menu-is-open {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-color: transparent;

        .ipcc-react-select__indicators {
          transform: rotate(180deg);
        }
      }
    }

    .ipcc-react-select__menu {
      height: auto;

      z-index: 10001;

      margin-top: 0.5em;

      border: 1px solid $primary-color;
      border-top: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      box-sizing: border-box;

      background-color: white;

      @include shadow-8dp();

      .ipcc-react-select__menu-list {
        box-sizing: border-box;
        padding: 4px 0px;

        background-color: $primary-color;

        .ipcc-react-select__option {
          display: flex;
          align-items: center;

          width: 100%;
          height: 24px;

          border-radius: 4px;

          font-size: 1em;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1em;
          letter-spacing: normal;
          text-align: start;
          color: white;

          white-space: nowrap;
          text-overflow: ellipsis;

          overflow: hidden;

          transition: color 50ms linear, background-color 50ms linear;

          &.ipcc-react-select__option {
            cursor: pointer;
          }

          &.ipcc-react-select__option--is-focused {
            color: $primary-color-accent;
            background-color: #639acc;
          }

          &.ipcc-react-select__option--is-selected {
            background-color: #3679b8;

            color: $primary-color-accent;
          }
        }
      }
    }
  }
}
