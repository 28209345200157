.radio-list-selector {
  display: flex;
  flex-direction: column;

  .radio-list-selector__title {
    font-weight: bold;
    padding-bottom: 1em;
    align-self: center;
    text-transform: uppercase;
  }

  .radio-list-selector__body {
    display: flex;
    flex-direction: column;
    width: auto;
    .radio-list-selector__radio {
      padding-bottom: 1em;
      &.disabled {
        // pointer-events: none;
      }
    }
  }
}
