@import 'partials/variables', 'partials/shadows';

.regional-information-mobile-warning {
  position: fixed;

  bottom: 0px;
  left: 0px;

  width: 100%;
  height: 100px;

  z-index: 10000;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @include shadow-to-top();

  background-color: $primary-color;

  padding: 1.5em 1em 1em 1em;

  box-sizing: border-box;

  .content {
    font-size: 0.8em;
    color: $primary-color-accent;
  }

  .close-icon {
    position: absolute;

    width: 20px;
    height: 20px;

    top: 0.5em;
    right: 0.5em;

    transform: rotate(45deg);

    path {
      fill: $primary-color-accent;
    }
  }
}

.regional-information-mobile-warning-transition-enter {
  opacity: 0;
  transform: translateY(110px);
}
.regional-information-mobile-warning-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms linear, transform 300ms ease-in-out;
}
.regional-information-mobile-warning-transition-exit {
  opacity: 1;
  transform: translateY(0px);
}
.regional-information-mobile-warning-transition-exit-active {
  opacity: 0;
  transform: translateY(110px);
  transition: opacity 300ms linear, transform 300ms ease-in-out;
}
