@import 'partials/variables';

.charts-error-boundary {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 1.75em;

    color: $primary-color;
    text-transform: uppercase;
    letter-spacing: 4px;

    margin-bottom: 1em;
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .body-message {
      color: #666;
      font-weight: lighter;

      a {
        color: $primary-color;
        font-weight: bold;
        text-decoration: none;
      }
    }

    .code-container {
      color: $primary-color;

      font-size: 1.5em;

      margin-top: 1em;
      max-width: 60vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      cursor: pointer;

      &:hover {
        color: darken($primary-color, 20);
      }
    }
  }
}
