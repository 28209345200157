@import 'partials/variables';
@import 'partials/shadows';
@import 'partials/breakpoints';
@import './BaseControl.scss';

@mixin disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  cursor: not-allowed;
  // pointer-events: none;

  svg {
    path {
      fill: #777;
    }
    circle {
      stroke: #777;
    }
  }

  &:hover {
    background-color: #eaeaea;
    box-shadow: none;
    svg {
      path {
        fill: #777;
      }
      circle {
        stroke: #777;
      }
    }
  }
}

.expandable-base-control {
  position: relative;

  right: -2px;

  z-index: 1010;

  * {
    box-sizing: border-box;
  }

  .father-control {
    position: relative;

    height: 40px;
    width: 40px;
    max-height: 40px;
    max-width: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    align-self: center;
    justify-self: center;

    z-index: 1001;

    background-color: $primary-color-accent;

    border: 2px solid $primary-color;
    border-radius: 50%;

    margin: 8px;

    cursor: pointer;

    transition: background-color 200ms ease, box-shadow 200ms ease;

    svg {
      path {
        transition: fill 200ms linear;
        fill: $primary-color;
      }
      circle {
        transition: stroke 200ms linear;
        stroke: $primary-color;
      }
    }

    @media (min-width: 767px) {
      &:hover,
      &:focus {
        background-color: $primary-color;

        @include shadow-3dp();

        svg {
          path {
            fill: $primary-color-accent;
          }
          circle {
            stroke: $primary-color-accent;
          }
        }
      }
    }

    &:active {
      box-shadow: none;
      background-color: $primary-color;
      color: $primary-color-accent;

      // @include shadow-3dp();

      svg {
        path {
          fill: $primary-color-accent;
        }
        circle {
          stroke: $primary-color-accent;
        }
      }
    }

    &.active {
      background-color: $primary-color;
      svg {
        path {
          transition: fill 0.15s;
          fill: $primary-color-accent;
        }
        circle {
          transition: stroke 150ms linear;
          stroke: $primary-color-accent;
        }
      }
    }
  }

  .expandable-container {
    position: absolute;

    height: 40px;
    width: 40px;
    max-height: 40px;
    max-width: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    align-self: center;
    justify-self: center;

    z-index: 10;

    margin: 8px;

    top: 0px;
    left: 0px;

    overflow: visible;

    z-index: 1000;

    .internal-child-control {
      position: absolute;

      top: 2.5px;
      left: 0px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 36px;
      height: 36px;

      background-color: $primary-color-accent;
      color: $primary-color;

      border: 2px solid $primary-color;
      border-radius: 50%;

      opacity: 0;

      transition: transform 200ms ease-in-out, opacity 200ms linear;

      @include hover-tooltip();

      cursor: pointer;

      svg {
        path {
          fill: $primary-color;
        }
        .nc-label {
          fill: $primary-color-accent;
        }
        circle {
          stroke: $primary-color;
        }
      }

      @media (min-width: 767px) {
        &:hover,
        &:focus {
          background-color: $primary-color;
          color: $primary-color-accent;

          svg {
            path {
              fill: $primary-color-accent;
            }
            .nc-label {
              fill: $primary-color;
            }
            circle {
              stroke: $primary-color-accent;
            }
          }
        }
      }

      &:active,
      &.active {
        box-shadow: none;
        background-color: $primary-color;
        color: $primary-color-accent;

        // @include shadow-3dp();

        svg {
          circle {
            stroke: $primary-color-accent;
            transition: stroke 150ms;
          }
          path {
            fill: $primary-color-accent;
            transition: fill 150ms;
          }
        }
      }

      &.disabled {
        @include disabled();
      }
    }
  }

  &.opened {
    .father-control {
      background-color: $primary-color;
      color: $primary-color-accent;

      svg {
        path {
          fill: $primary-color-accent;
        }
        circle {
          stroke: $primary-color-accent;
        }
      }

      &:after {
        content: '';
      }
    }
    .expandable-container {
      z-index: 1002;
      pointer-events: none;
      .internal-child-control {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &.closed:not(.mobile) {
    .father-control {
      @include hover-tooltip(true);
    }
  }

  &.mobile {
    .expandable-container {
      .internal-child-control {
        top: -5px;
      }
    }

    &.closed {
      .father-control {
        @media (orientation: portrait) {
          @include tooltip();
        }
      }
    }
  }
}
