@import 'partials/variables';

.hatching-segments-container {
  display: block;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;

  width: 20px;
  height: 45px;

  box-sizing: border-box;

  margin: 0.25em 0px;

  overflow: hidden;

  transition: height 300ms ease-in-out;

  .hatching-segment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;

    border: 1px solid #333;
    box-sizing: border-box;
    &.simple {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Cdefs%3E%3Cpattern id='basic-pattern' patternUnits='userSpaceOnUse' width='8' height='8' %3E%3Cpath d='M-1,1 l4,-4M0,8 l8,-8M6,10 l4,-4' style='stroke: grey; stroke-width: 1' /%3E%3C/pattern%3E%3C/defs%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23basic-pattern)' /%3E%3C/svg%3E%0A");
    }

    &:focus {
      border-color: $primary-color;
      border-width: 3px;
    }
  }

  &.hide {
    display: none;
  }

  &.small {
    height: 45px;
  }

  &.big {
    height: 65px;
  }
}
