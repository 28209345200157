@import 'partials/variables';

.ipcc-mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1100;
  background-color: rgba($primary-color, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .ipcc-mobile-icon__container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-basis: 30%;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 200px;
  }

  .ipcc-mobile-links__container {
    height: 100%;
    width: 100%;
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    align-items: center;

    .ipcc-mobile-link {
      font-size: x-large;
      text-decoration: none;
      color: $primary-color-accent;
      text-transform: uppercase;

      transition: transform 300ms ease-in-out, opacity 300ms linear;
    }
  }

  .ipcc-mobile-icons__container {
    height: 100%;
    width: 100%;
    flex-basis: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lang-select {
    background-color: transparent;
    border: none;
    outline: none;
    color: $primary-color-accent;
    cursor: pointer;
    font-size: 1.5em;
    transition: transform 300ms ease-in-out, opacity 300ms linear;

    option {
      background-color: rgba($primary-color, 0.8);
    }
  }
}

// TRANSITIONS

// Main menu
.ipcc-mobile-menu-transition-enter {
  transform: translateY(-800px);
  opacity: 0;
}

.ipcc-mobile-menu-transition-enter-active {
  transform: translateY(0px);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}

.ipcc-mobile-menu-transition-exit {
  transform: translateY(0px);
  opacity: 1;
}

.ipcc-mobile-menu-transition-exit-active {
  transform: translateY(-800px);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}

// IPCC logo
.ipcc-logo-transition-enter {
  transform: translateY(-150px);
  opacity: 0;
}

.ipcc-logo-transition-enter-active {
  transform: translateY(0px);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}

.ipcc-logo-transition-exit {
  transform: translateY(0px);
  opacity: 1;
}

.ipcc-logo-transition-exit-active {
  transform: translateY(-150px);
  opacity: 0;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}

// Links
.ipcc-link-transition-enter {
  transform: translateX(-300px);
  opacity: 0;
}

.ipcc-link-transition-enter-active {
  transform: translateX(0px);
  opacity: 1;
}

.ipcc-link-transition-exit {
  transform: translateX(0px);
  opacity: 1;
}

.ipcc-link-transition-exit-active {
  transform: translateX(-300px);
  opacity: 0;
}
