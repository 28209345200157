@import 'partials/variables';
@import 'partials/shadows';
@import 'partials/animations';

.permalink-tooltip {
  width: 400px;
  height: auto;
  border: none;

  overflow: hidden;

  padding: 1em;

  @include shadow-8dp();

  background-color: $primary-color;
  color: $primary-color-accent;

  font-weight: 300;

  .container {
    width: 100%;

    text-align: center;
  }

  .title {
    font-weight: 400;
  }

  .code-wrapper {
    cursor: copy;
    margin: 1em 0px 2em 0px;
    font-size: 1em;
    line-height: 150%;
    font-weight: bold;
    text-align: center;
    @include hover-underline-animation($primary-color-accent);
  }

  .list {
    padding-left: 1em;
    list-style-type: none;
    margin: 0;

    text-align: start;

    li {
      line-height: 150%;
      padding-bottom: 1em;
    }
  }
}
