@import 'partials/react-tooltip';

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

* {
  outline: 2px solid invert;
  outline-offset: 2px;
  outline: none;
}

::-webkit-scrollbar-track {
  background-color: #ddd;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: darken(#5492cd, 5);
}
