@import 'partials/variables';

.loading-progress-indicator-transition-enter {
  transform: scale(0.5);
  opacity: 0;
}

.loading-progress-indicator-transition-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}

.loading-progress-indicator-transition-exit {
  transform: scale(1);
  opacity: 1;
}

.loading-progress-indicator-transition-exit-active {
  transform: scale(0.5);
  opacity: 0;
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}

.ipcc-atlas__loading-indicator {
  position: relative;
  margin: 0 auto;
  width: 100px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

$color1: #820087;
$color2: $primary-color;
$color3: #d62d20;
$color4: #ffa700;

@keyframes color {
  
  100%,
  0% {
    stroke: $color1;
  }
  40% {
    stroke: $color2;
  }
  66% {
    stroke: $color3;
  }
  80%,
  90% {
    stroke: $color4;
  }
}
