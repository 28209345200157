@import 'partials/variables';
@import 'partials/breakpoints';
@import 'partials/animations';

.static__page {
  height: 100%;

  padding: 0px 5em;

  font-weight: 300;

  border: 40px solid $primary-color;
  border-top: 0px;
  overflow-y: auto;

  color: #666666;

  strong {
    font-weight: 500;
  }

  .highlight {
    font-weight: 400;
    line-height: 180%;
    font-size: 1.3em;
    color: $primary-color;
    padding-bottom: 1em;

    max-width: 60%;
    margin: 0 auto;
  }

  .highlight-title {
    color: $primary-color;
    max-width: 60%;
    margin: 0 auto;
  }

  .static__page-body {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;

    padding-bottom: 2em;

    max-width: 60%;
    margin: 0 auto;
    line-height: 180%;
    font-weight: 300;
    font-size: 1.2em;

    &.with-menu {
      margin: 0 10% 0 400px;
    }

    .list {
      padding-left: 0;
      list-style: none;
      .list-item {
        .list-title {
          font-weight: bold;
          text-transform: uppercase;
          color: #666;
        }
        padding-bottom: 2em;
      }

      &.spaced {
        li {
          padding: 1em 0px;
        }
      }
    }
  }

  .static-code {
    background-color: #eeeeee;
    border: 1px solid #aaaaaa33;
    border-radius: 4px;
    max-width: 60%;
    padding: 1em;
    font-size: 16px;
    font-weight: 300;

    code {
      font-family: inherit;
    }
  }

  .static-link {
    color: $primary-color;
    text-decoration: none;
    font-weight: 400;
  }

  &.documentation__page {
    position: relative;
    .memoriam {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      margin: 2em auto -1em auto;

      .memoriam-text {
        color: #aaa;

        font-style: italic;

        font-size: 1.3em;

        text-align: center;
      }
    }
  }

  &.about__page {
    .doc-list.two-row-list {
      columns: 2;
      font-size: 1em;
      .doc-li {
        margin-bottom: 0.5em;
      }
    }
  }
}

.error-boundary__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-boundary__message {
    font-weight: 300;
  }

  .error-boundary__action-redirect {
    color: $primary-color;

    font-size: 1.5em;

    font-weight: 400;

    cursor: pointer;

    @include hover-underline-animation($primary-color);
  }
}

@include respond-to(small) {
  .static__page {
    padding: 0px 1em;
    border: 5px solid $primary-color;
    border-top: 0px;

    color: #333333;

    .highlight {
      max-width: unset;
      font-size: 1.1em;
      padding-bottom: 1em;
    }

    .highlight-title {
      max-width: unset;
    }

    .static__page-body {
      font-size: 1em;

      max-width: unset;

      .list {
        &.spaced {
          li {
            padding: 0.5em 0px;
          }
        }
      }
      .static-code {
        max-width: unset;
      }
    }
  }
}
