@import 'partials/variables';
@import 'partials/breakpoints';

.core-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ipcc-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }

  .ipcc-app__header {
    background-color: $primary-color;
    color: $primary-color-accent;
  }
}

.alert-transition-enter {
  transform: translateX(400px);
  opacity: 0;
}

.alert-transition-enter-active {
  transform: translateX(0px);
  opacity: 1;
  transition: transform 300ms ease-in, opacity 200ms linear;
}

.alert-transition-exit {
  transform: translateX(0px);
  opacity: 1;
}

.alert-transition-exit-active {
  transform: translateX(400px);
  opacity: 0;
  transition: transform 300ms ease-in, opacity 200ms linear;
}

@include respond-to(small) {
  .alert-transition-enter {
    transform: translateY(200px);
    opacity: 0;
  }

  .alert-transition-enter-active {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 300ms ease-in, opacity 300ms linear;
  }

  .alert-transition-exit {
    transform: translateY(0px);
    opacity: 1;
    transition: transform 300ms ease-in, opacity 300ms linear;
  }

  .alert-transition-exit-active {
    transform: translateY(200px);
    opacity: 0;
    transition: opacity 300ms linear;
  }
}
