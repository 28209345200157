@import 'partials/variables';
@import 'partials/shadows';
@import 'partials/breakpoints';

.select-all-control {
  position: relative;

  top: 6px;
  left: 12px;

  width: 30px;
  height: 30px;

  margin-right: 0.5em;

  background-color: $primary-color-accent;
  border: 2px solid $primary-color;

  border-radius: 4px;

  cursor: pointer;

  transition: background-color 150ms linear;

  svg {
    padding: 0px;
    margin: 0px;

    path {
      fill: $primary-color;

      stroke-width: 4;
      stroke: $primary-color;
      transition: stroke 150ms linear;

      transition: fill 150ms linear, stroke 150ms linear;
    }
  }

  &:hover,
  &.active {
    background-color: $primary-color;

    svg {
      path {
        fill: $primary-color-accent;
        stroke: $primary-color-accent;
      }
    }
  }

  &.disabled {
    background-color: #eaeaea;
    border-color: #eaeaea;

    svg {
      path {
        fill: #777777;
        stroke: #777777;
      }
    }
  }
}
