@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown__body {
  position: absolute;
  z-index: 9999;
  min-width: 25%;
  &.fade {
    height: auto;
    animation: fade .3s ease-in 1;
  }
}

.drop-down-body-transition-exit {
  opacity: 1;
}

.drop-down-body-transition-exit-active {
  opacity: 0;
  transition: opacity 100ms linear;
}