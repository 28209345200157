@import 'partials/variables';

.ipcc-map__caption {
  position: absolute;
  width: calc(100% - 80px);

  display: flex;
  flex-direction: row;

  justify-content: center;

  background-color: $primary-color-accent;

  backdrop-filter: blur(10px);

  bottom: 40px;

  .map__caption-wrapper {
    font-weight: 400;

    color: $primary-color;

    font-size: 1.2em;
    width: 100%;
    margin: auto;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: 1px solid #eee;
  }

  &.mirroring {
    .map__caption-wrapper {
      text-overflow: ellipsis;
      white-space: normal;
      font-size: 1em;
    }
  }
}
