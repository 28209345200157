@import 'partials/variables';
@import 'partials/breakpoints';
@import 'partials/shadows';
@import './base/BaseControl.scss';

.mirror-control {
  svg {
    path {
      fill: transparent !important;
      stroke: $primary-color;
    }
  }

  @media (min-width: 767px) {
    &:hover {
      background-color: $primary-color;

      @include shadow-3dp();

      svg path {
        fill: transparent;
        stroke: $primary-color-accent;
      }
    }
  }
  &:active {
    box-shadow: none;
    background-color: $primary-color;
    color: $primary-color-accent;

    svg path {
      fill: transparent;
      stroke: $primary-color-accent;
    }
  }
  &.active {
    background-color: $primary-color;
    svg path {
      transition: fill 0.15s;
      fill: transparent;
      stroke: $primary-color-accent;
    }
  }
}

@mixin disabled {
  background-color: #eaeaea;
  border-color: #eaeaea;
  cursor: not-allowed;
  pointer-events: none;

  svg path {
    fill: #777777;
  }

  &:hover {
    background-color: #eaeaea;
    box-shadow: none;
    svg path {
      fill: #777;
    }
  }
}
.zoom-control {
  &.zoom-in-control.max-disabled {
    @include disabled();
  }

  &.zoom-out-control.min-disabled {
    @include disabled();
  }
}

.metadata-control.disabled {
  @include disabled();
}

.graticule-control.disabled {
  @include disabled();
}

.select-all-control.disabled {
  @include disabled();
}

.select-all-control {
  svg path {
    stroke-width: 4;
    stroke: $primary-color;
    transition: stroke 150ms linear;
  }
  &:hover {
    svg path {
      stroke: white;
    }
  }
}

.mobile-map-controls__container {
  position: fixed;

  display: flex;
  flex-direction: column;

  bottom: 32px;
  right: 10px;

  z-index: 1006;

  @media (orientation: landscape) {
    flex-direction: row;
  }

  .mobile-map-controls__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    border-radius: 50%;

    background-color: $primary-color;

    @include shadow-3dp();

    svg {
      transition: transform 600ms cubic-bezier(0.64, 0.57, 0.67, 1.53);
      path {
        fill: $primary-color-accent;
      }
    }

    &.open {
      svg {
        transform: rotate(135deg);
      }
    }
  }

  .mobile-map-controls__controls {
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      flex-direction: row;
    }
  }
}

.map-projection-container-transition-enter {
  opacity: 0;
  transform: scale(0);
}
.map-projection-container-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: 100% 0%;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.map-projection-container-transition-exit {
  opacity: 1;
  transform: scale(1);
}
.map-projection-container-transition-exit-active {
  opacity: 0;
  transform-origin: 100% 0%;
  transform: scale(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.mobile-control-transition-enter {
  opacity: 0;
  @media (orientation: landscape) {
    transform: translateX(50px);
  }
  @media (orientation: portrait) {
    transform: translateY(50px);
  }
}
.mobile-control-transition-enter-active {
  @media (orientation: landscape) {
    transform: translateX(0px);
  }
  @media (orientation: portrait) {
    transform: translateY(0px);
  }
  opacity: 1;
  transition: opacity 150ms linear, transform 150ms ease-in-out; // cubic-bezier(0.64, 0.57, 0.67, 1.53);
}
.mobile-control-transition-exit {
  @media (orientation: landscape) {
    transform: translateX(0px);
  }
  @media (orientation: portrait) {
    transform: translateY(0px);
  }
  opacity: 1;
}
.mobile-control-transition-exit-active {
  @media (orientation: landscape) {
    transform: translateX(50px);
  }
  @media (orientation: portrait) {
    transform: translateY(50px);
  }
  opacity: 0;

  transition: opacity 150ms linear, transform 150ms ease-in-out;
}
