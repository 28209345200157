@import 'partials/variables';
@import 'partials/shadows';

.ipcc-feature-tooltip {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  font-size: 1em;

  padding: 1em;

  background-color: transparent;

  color: $primary-color-accent;

  cursor: default;

  .close-icon {
    margin-top: -0.6em;
    position: absolute;
    right: 4px;
    svg {
      cursor: pointer;
      transform: rotate(45deg);
      path {
        fill: $primary-color-accent;
        transition: fill 150ms linear;
      }

      &:focus {
        path {
          fill: darken($primary-color, 30);
        }
      }
    }

    &:hover {
      svg {
        path {
          fill: darken($primary-color, 30);
        }
      }
    }
  }

  .feature-tooltip-title {
    padding: 0 0 0.5em 0;
    font-size: 1em;
  }

  .feature-tooltip-point,
  .feature-tooltip-value {
    height: 30px;

    .label {
      font-weight: 300;

      margin-right: 0.25em;
    }

    .value {
      font-weight: bold;
    }
  }
}
