@import 'partials/variables';
@import '../../map-controls/base/BaseControl.scss';

.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
  margin-left: 1em;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  &.checked {
    font-weight: bold;
  }

  .label {
    position: relative;
    margin-right: 1em;
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    border: 2px solid #c8ccd4;
    border-radius: 100%;
    -webkit-tap-highlight-color: transparent;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: $primary-color-accent;
      transform: scale(0);
      transition: all 0.2s ease;
      opacity: 0.32;
      pointer-events: none;
    }
  }

  &:not(.disabled) {
    &:hover .label:after,
    &:focus .label:after {
      transform: scale(2.6);
    }
    input[type='radio']:checked + .label {
      border-color: $primary-color-accent;
    }
    input[type='radio']:checked + .label:after {
      transform: scale(1);
      transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
      opacity: 1;
    }
  }

  .description-icon-wrapper {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    overflow: visible;
    .description-icon {
      cursor: pointer;

      margin-left: 0.5em;

      transition: transform 100ms ease-in-out;

      path {
        fill: white !important;
      }
    }

    &:hover {
      .description-icon {
        transform: scale(1.2);
      }
    }
  }

  &.disabled {
    color: #c8ccd4;
    cursor: default;
  }

  &.highlight {
    margin-left: 0px;
  }

  .hidden {
    display: none;
  }
}
