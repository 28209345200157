@import 'partials/variables';
@import 'partials/breakpoints';
@import 'partials/animations';
@import 'partials/shadows';

.ipcc-app__header {
  display: flex;
  flex-direction: row;

  padding: 0.8em 1em;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  min-height: 86px;

  .ipcc-app__left-side {
    display: flex;
    flex-direction: row;

    min-width: 50%;
    flex-grow: 1;

    align-items: center;
    justify-content: flex-start;
    padding-left: 2em;

    .ipcc-logo-link {
      img {
        padding-right: 1em;
        cursor: pointer;

        &.ipcc-logo {
          margin-top: -20px;
          margin-bottom: -20px;
          width: 120px;
        }
      }
      &:focus {
        img {
          outline: 1px solid white;
          outline-offset: 2px;
        }
      }
    }

    .ipcc-text__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      text-align: left;
      font-size: 20px;
      font-weight: 500;
      line-height: 1em;

      box-sizing: border-box;

      .link-header {
        color: white;

        text-decoration: none;

        &:focus {
          color: darken($primary-color-accent, 20);
          text-decoration: underline;
        }
      }

      b {
        font-weight: unset;
      }
    }
  }

  .ipcc-app__right-side {
    display: flex;
    flex-direction: row;

    max-width: 50%;

    align-items: center;
    justify-content: flex-end;
    padding-right: 1.5em;

    .ipcc-app__link-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .ipcc-header-link {
        color: $primary-color-accent;
        margin-right: 1em;
        text-decoration: none;
        cursor: pointer;
        &:not(.active) {
          @include hover-underline-animation($primary-color-accent);
        }

        &.active {
          font-weight: bold;
        }

        &:focus {
          color: darken($primary-color-accent, 25);
        }
      }
    }

    .ipcc-select {
      width: 80px;
      cursor: pointer;
    }

    .show-tour-icon {
      position: relative;

      top: 4px;

      margin-left: 1em;

      cursor: pointer;

      path {
        fill: $primary-color-accent;

        transition: fill 100ms linear;
      }

      &:hover {
        path {
          fill: darken($color: $primary-color, $amount: 30);
        }
      }

      &:focus {
        path {
          fill: darken($primary-color, 30);
        }
      }
    }
  }

  .ipcc-header__menu-icon {
    z-index: 1101;
    display: none;
  }

  .home-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    margin-right: 1em;

    z-index: 1010;

    box-sizing: border-box;

    .home-link-container {
      .home-link {
        color: $primary-color-accent;
        margin-right: 1em;
        text-decoration: none;

        &:focus {
          color: darken($primary-color-accent, 25);
        }
      }

      .dropdown-arrow {
        position: relative;

        top: 2px;
        left: 4px;

        transition: transform 150ms ease-in-out;

        path {
          fill: $primary-color-accent;
        }
      }
    }

    .dropdown-body {
      position: absolute;

      height: 0px;
      width: 275px;

      top: 25px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      overflow: hidden;

      background-color: $primary-color;
      border-radius: 4px;

      opacity: 0;

      box-sizing: border-box;

      @include shadow-8dp();

      transition: height 150ms ease-in-out, opacity 150ms linear;

      .dropdown-link-container {
        margin: 0.25em 0px;

        padding: 0.5em;

        width: 100%;

        height: 100%;

        text-align: center;
        box-sizing: border-box;

        transition: background-color 150ms linear;

        .ipcc-header-link {
          margin: 0px;
        }

        &:hover:not(.disabled) {
          background-color: $primary-color-lg;
        }

        &.disabled {
          user-select: none;
          pointer-events: none;
          .ipcc-header-link {
            user-select: none;
            pointer-events: none;
            color: #bbb;
          }
        }
      }
    }

    &:hover {
      .dropdown-arrow {
        transform: rotate(180deg);
      }
      .dropdown-body {
        height: 135px;
        opacity: 1;
      }
    }
  }
}

@mixin responsive {
  padding: 8px;
  box-sizing: border-box;
  min-height: unset;

  .link-header.title {
    font-size: 12px;
    line-height: 1em;
  }

  .ipcc-app__left-side {
    width: 60%;
    padding-left: 0.5em;
    max-width: 80%;

    .ipcc-text__container {
      font-size: 12px;
    }
  }
  .ipcc-app__right-side {
    display: none;
  }

  .ipcc-header__menu-icon {
    display: inline;
  }

  img.ipcc-logo {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
    width: 100px;
  }
}

@include respond-to(small) {
  .ipcc-app__header {
    @include responsive();
  }
}

@include respond-to(large) {
  .ipcc-app__header.mobile {
    @include responsive();
  }
}
