@import 'partials/variables';

.reactour__helper {
  --reactour-accent: transparent;
  background-color: $primary-color !important;

  border-radius: 5px;
  max-width: unset !important;

  .reactour__close {
    path {
      fill: $primary-color-accent !important;
    }
  }

  .ipcc-basic-tour-step {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    color: $primary-color-accent;

    .basic-tour-step-body {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      max-width: 400px;
    }

    .basic-tour-buttons-container {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .basic-tour-button {
        border: none;

        padding: 0.5em 1em;

        border-radius: 2px;

        color: $primary-color;

        text-transform: uppercase;
        font-size: 0.8em;
        letter-spacing: 4px;

        cursor: pointer;

        transition: background-color 100ms linear, color 100ms linear;

        &.next-button {
          margin-left: 1em;
        }

        &:hover {
          background-color: lighten($color: $primary-color, $amount: 10);
          color: $primary-color-accent;
        }
      }
    }
  }

  .ipcc-initial-step {
    .basic-tour-step-body {
      flex-direction: column;
      max-width: unset;

      .ipcc-tour-step-title {
        white-space: nowrap;
      }
    }
  }
}

