@import 'partials/variables';

.ipcc-controls__container {
  position: absolute;
  display: grid;

  width: 54px;
  max-height: 100%;
  // overflow: hidden;

  padding-right: 7px;

  top: 0;
  right: 0;
  padding-top: 10px;

  grid-template-columns: 60px;
  grid-template-rows: 60px;
  grid-auto-rows: 60px;
  grid-auto-columns: 60px;

  z-index: 1000;

  &.left {
    left: 0px;
    // padding: 16px;
  }

  &.vertical {
    grid-auto-flow: row;
  }

  &.collapsed {
    
    grid-template-rows: 50px;
    grid-auto-rows: 50px;

    .first-group-end, .second-group-end {
      grid-row-start: unset;
      grid-row-end: unset;
      align-self: center;
    }

    .hide-on-collapse {
      display: none;
    }

    .projections-options__container {
      bottom: 353px;
    }

    .download-options__container {
      bottom: 220px;
    }

    .map-controls-separators {
      display: none;
    }
  }
}
