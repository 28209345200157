@import 'partials/variables';

.ipcc-date-selector {
  display: flex;
  flex-direction: column;

  position: relative;

  overflow: visible;

  .ipcc-select {
    padding: 0.3em 0em;

    .ipcc-react-select__menu-portal {
      position: absolute;
      z-index: 1000;
      top: -320px;
      left: 0px;


    }
  }
}
