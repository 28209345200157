@import 'partials/shadows', 'partials/breakpoints', 'partials/variables';

$error-text-color: #990002;

$success-text-color: #004f00;

$warning-text-color: #c47900;

$info-text-color: #00aad0;

.ipcc-alert__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  position: fixed;
  right: 0px;
  bottom: 0px;
  width: calc(500px + 1em);
  padding-right: 1em;
  z-index: 100001;
  box-sizing: border-box;

  .ipcc-alert {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    box-sizing: border-box;

    width: 350px;
    max-width: 500px;
    min-height: 80px;

    border-left: 4px solid transparent;

    justify-content: space-between;
    align-items: center;
    border-radius: 2px;

    background-color: $primary-color-accent;

    padding: 0.5em;

    margin-bottom: 1em;

    @include shadow-8dp();

    .ipcc-alert__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      box-sizing: border-box;

      height: 100%;
      width: 100%;
      overflow: hidden;

      justify-content: space-between;
      .ipcc-alert__title {
        font-size: 18px;
        text-transform: uppercase;

        margin-bottom: 1em;
      }

      .ipcc-alert__message {
        flex-basis: 90%;
        max-width: calc(100% - 24px);
        font-size: 16px;
        overflow-y: auto;

        color: #666 !important;

        a {
          color: $primary-color;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }

    .close-icon {
      position: absolute;

      right: 0.5em;
      top: 0.5em;

      cursor: pointer;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      path {
        fill: $primary-color;
      }
    }

    &.success {
      border-color: $success-text-color;
      color: $success-text-color;
      .close-icon path {
        fill: $success-text-color;
      }
    }

    &.error {
      border-color: $error-text-color;
      color: $error-text-color;
      .close-icon path {
        fill: $error-text-color;
      }
    }

    &.warning {
      border-color: $warning-text-color;
      color: $warning-text-color;
      .close-icon path {
        fill: $warning-text-color;
      }
    }

    &.info {
      border-color: $info-text-color;
      color: $info-text-color;
      .close-icon path {
        fill: $info-text-color;
      }
    }
  }
}

@include respond-to(small) {
  .ipcc-alert__container {
    width: 100%;
    padding: 0px;
    margin: 0px;

    .ipcc-alert {
      width: 100%;
      margin: 0px;
      max-width: unset;
      border-radius: 0px;
    }
  }
}
