@import 'partials/variables';

.ipcc-menu-button {
  position: relative;
  width: 30px;
  height: 25px;
  right: 1em;

  .stick {
    position: absolute;
    width: 30px;
    border-radius: 5px;
    height: 5px;
    background-color: $primary-color-accent;

    right: 1px;
    opacity: 1;

    transition: right 300ms linear, top 300ms linear, transform 300ms linear,
      top 300ms linear, opacity 300ms linear;
      
    &.stick-1 {
      top: 0px;
    }
    &.stick-2 {
      top: 10px;
    }
    &.stick-3 {
      top: 20px;
    }
  }

  &.opened {
    .stick-2 {
      right: 100%;
      opacity: 0;
    }
    .stick-1 {
      top: 10px;
      transform: rotate(45deg);
    }
    .stick-3 {
      top: 10px;
      transform: rotate(-45deg);
    }
  }
}
