@import 'partials/shadows';
@import 'partials/variables';

.ipcc-tooltip__backdrop {
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.ipcc-tooltip {
  z-index: 100002;
  position: absolute;
  width: auto;
  height: auto;
  border: 1px solid #6a6a6a;
  border-radius: 4px;
  background-color: white;
  @include shadow-24dp();

  .close-icon {
    position: absolute;
    right: 0.2em;
    top: 0.2em;
    cursor: pointer;
    svg {
      cursor: pointer;
      transform: rotate(45deg);
      path {
        fill: $primary-color-accent;
        transition: fill 100ms linear;
      }
      &:focus {
        path {
          fill: darken($primary-color, 30);
        }
      }
    }
    &:hover {
      path {
        fill: darken($primary-color, 30);
      }
    }
  }
}
