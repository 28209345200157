@import 'partials/variables', 'partials/shadows';

.atlas-credits {
  position: fixed;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  z-index: 10001;

  .credits-backdrop {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: #fff;
    opacity: 0.7;

    z-index: 1;
  }

  .close-icon {
    position: fixed;

    top: 4em;
    right: calc(10px + 4em);

    width: 40px;
    height: 40px;

    z-index: 3;

    transform: rotate(45deg);

    cursor: pointer;

    transition: transform 150ms ease-in-out;

    path {
      fill: $primary-color;

      transition: fill 150ms linear;
    }

    &:hover {
      transform: scale(1.2) rotate(-45deg);

      path {
        fill: darken($primary-color, 30);
      }
    }
  }

  .credits-modal {
    overflow: auto;

    width: 500px;
    height: 600px;

    opacity: 1;

    background-color: $primary-color;

    padding: 1em 2em;

    border-radius: 4px;

    color: $primary-color-accent;

    @include shadow-8dp();

    z-index: 2;

    p {
      font-size: 1em;
      line-height: 150%;

      font-weight: 300;

      text-align: justify;

      &.credits-title {
        font-size: 1.2em;
        font-weight: bold;

        text-align: center;
      }

      &.list-title {
        font-weight: normal;
        padding-top: 1em;
      }

      a {
        color: $primary-color-accent;
        text-decoration-color: $primary-color-accent;
      }
    }

    ul > li {
      margin: 0.5em 0px;
      font-weight: 300;
    }
  }
}
