@import 'partials/variables';
@import 'partials/shadows';
@import 'partials/breakpoints';

@mixin tooltip {
  &[data-tooltip]:after {
    right: 36px;
    border-radius: 5px;
    background: rgba($primary-color, 0.8);
    backdrop-filter: blur(10px);
    color: $primary-color-accent;
    font-weight: bold;
    content: attr(data-tooltip);
    font-size: 12px;
    opacity: 1;
    padding: 5px 7px;
    margin-right: 0px;
    position: absolute;
    margin-left: 8px;
    white-space: nowrap;

    @include respond-to(small) {
      margin-right: 12px;
    }
  }
}

@mixin hover-tooltip($vertical: false) {
  &:after {
    @if $vertical {
      right: 50px;
    } @else {
      top: 40px;
    }
    border-radius: 5px;
    background: rgba($primary-color, 0.8);
    backdrop-filter: blur(10px);
    color: $primary-color-accent;
    font-weight: bold;
    content: attr(data-tooltip);
    font-size: 12px;
    opacity: 1;
    padding: 5px 7px;
    margin-right: 0px;
    position: absolute;
    margin-left: 0px;
    white-space: nowrap;

    max-width: 300px;
    width: auto;

    z-index: 1002;

    opacity: 0;
    pointer-events: none;

    transition: opacity 200ms linear;

    @include respond-to(small) {
      margin-right: 12px;
    }
  }
  &[data-tooltip]:hover:after {
    opacity: 1;
  }
  &[data-tooltip]:focus:after {
    opacity: 1;
  }
}

.ipcc-base-control {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  position: relative;

  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  align-self: center;
  justify-self: center;

  z-index: 1001;

  background-color: $primary-color-accent;

  border: 2px solid $primary-color;
  border-radius: 50%;

  margin: 8px;

  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  cursor: pointer;

  @include hover-tooltip(True);

  svg {
    path {
      fill: $primary-color;
      stroke: $primary-color;
    }
  }

  @media (min-width: 767px) {
    &:hover,
    &:focus {
      background-color: $primary-color;

      @include shadow-3dp();

      svg path {
        fill: $primary-color-accent;
        stroke: $primary-color-accent;
      }
    }
  }

  &:active {
    box-shadow: none;
    background-color: $primary-color;
    color: $primary-color-accent;

    // @include shadow-3dp();

    svg path {
      fill: $primary-color-accent;
    }
    svg path {
      fill: $primary-color-accent;
    }
  }
  &.active {
    background-color: $primary-color;
    svg path {
      transition: fill 0.15s;
      fill: $primary-color-accent;
    }
  }

  &.first-group-end {
    grid-row-start: 3;
    grid-row-end: 5;
    align-self: flex-start;
    margin: 8px;
  }

  &.second-group-end {
    grid-row-start: 5;
    grid-row-end: 7;
    align-self: flex-start;
    margin: 8px;
  }

  @media (max-width: 767px) and (orientation: portrait) {
    @include tooltip();
  }
}
