// Colors

$primary-color: #5492cd;
$primary-color-lg: #6a9dd2;
$primary-color-accent: white;
$secondary-color: #4b0082;
$secondary-color-lg: #992aac;

._ {
  color: #8dd3c7;
  color: #ffffb3;
  color: #bebada;
  color: #fb8072;
  color: #80b1d3;
  color: #fdb462;
  color: #b3de69;
  color: #fccde5;
  color: #d9d9d9;
  color: #bc80bd;
}
