@import './variables';

div.__react_component_tooltip.tooltip {
  box-sizing: border-box;

  background-color: $primary-color;
  
  color: white;

  border-radius: 4px;

  text-align: center;

  line-height: 1.5em;

  z-index: 1020;

  word-wrap: normal;

  max-width: 300px;

  user-select: auto;
  
  &.place-top:before,
    &.place-top:after {
    border-top-color: $primary-color !important;
  }

  &.place-left:before,
  &.place-left:after {
    border-left-color: $primary-color !important;
  }

  &.place-right:before,
  &.place-right:after {
    border-right-color: $primary-color !important;
  }

  &.place-bottom:before,
  &.place-bottom:after {
    border-bottom-color: $primary-color !important;
  }

  .no-multiline {
    white-space: nowrap;
  }

  .high-z {
    z-index: 9999;
  }

  &.show {
    opacity: 1;
  }
}
