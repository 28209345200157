@import 'partials/variables';
@import 'partials/shadows';

.legend-edit-tooltip {
  width: 300px;
  height: auto;

  display: flex;
  flex-direction: column;
  border: none;

  padding: 1em;

  font-size: 0.8em;

  @include shadow-8dp();

  background-color: $primary-color;
  color: $primary-color-accent;

  transform-origin: center left;

  z-index: 1010;

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0%;
    margin-left: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent rgba($primary-color, 0.8) transparent transparent;
  }

  .form {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;

    flex-wrap: wrap;

    .label {
      flex-grow: 1;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      margin: 0.5em 0px;

      .input {
        border: 1px solid $primary-color;

        height: 24px;

        border-radius: 3px;
        padding-left: 0.5em;

        margin-left: 0.25em;

        max-width: 60px;

        background-color: lighten($color: $primary-color, $amount: 10);
        color: $primary-color-accent;
        outline: none !important;
      }

      &:nth-child(2) {
        padding-left: 1em;
      }
    }
  }

  .button-row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    margin-top: 1em;

    .button {
      height: 28px;

      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      border: 1px solid transparent;
      border-radius: 2px;

      background-color: darken($primary-color, 15);
      color: $primary-color-accent;
      cursor: pointer;

      transition: color 150ms linear, background-color 150ms linear;

      &.confirm {
        border-color: $primary-color;

        &:hover:not(:disabled) {
          color: $primary-color-accent;
          background-color: darken($primary-color, 10);
        }
      }

      &.cancel {
        border-color: rgb(158, 81, 81);
        background-color: rgb(158, 81, 81);
        &:hover {
          color: $primary-color-accent;
          background-color: rgb(109, 55, 55);
          border-color: rgb(109, 55, 55);
        }
      }

      &:not(:first-child) {
        margin-left: 0.5em;
      }

      &:disabled {
        color: #333;
        background-color: #aaa;
        border-color: #aaa;

        cursor: not-allowed;
      }

      &:focus {
        border-color: $primary-color-accent;
      }
    }
  }
}

.legend-config-tooltip-transition-enter {
  transform: scale(0.3);
  opacity: 0;
}
.legend-config-tooltip-transition-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}
.legend-config-tooltip-transition-exit {
  transform: scale(1);
  opacity: 1;
  transition: transform 300ms ease-in-out, opacity 300ms linear;
}
.legend-config-tooltip-transition-exit-active {
  transform: scale(0.3);
  opacity: 0;
}
