@import 'partials/breakpoints';
@import 'partials/variables';

.map__page {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  border-left: 40px solid $primary-color;
  border-right: 40px solid $primary-color;
  border-bottom: 40px solid $primary-color;

  overflow: hidden;

  @include respond-to(small) {
    border: none;
  }

  &.mobile {
    border: none;
  }

  &.mirroring {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .primary-map__wrapper {
    grid-column: 1;
  }
  .secondary-map__wrapper {
    grid-column: 2;
  }

  .download-control,
  .mirror-control {
    svg {
      padding-left: 0px;
    }
  }
}

.map-page-loading {
  position: absolute;
  left: calc(50vw - 50px);
  top: calc(50vh - 50px);
}

.chart-button-transition-enter {
  opacity: 0;
}

.chart-button-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.chart-button-transition-exit {
  opacity: 1;
}

.chart-button-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms linear;
}

.chart-button-transition-exit-done {
  opacity: 0;
}

.resizable-container-enter {
  height: 0px;
  max-height: 0px;
  min-height: 0px;
}

.resizable-container-enter-active {
  height: 500px;
  max-height: 500px;
  transition: height 200ms ease-in, max-height 200ms ease-in;
}

.resizable-container-exit {
  height: 500px;
  max-height: 500px;
}

.resizable-container-exit-active {
  height: 0px;
  max-height: 0px;
  transition: height 200ms ease-out, max-height 200ms ease-out;
}

.review-tooltip-transition-enter {
  .permalink-tooltip {
    transform: scale(0.3);
    opacity: 0;
  }
}
.review-tooltip-transition-enter-active {
  .permalink-tooltip {
    transform: scale(1);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms linear;
  }
}
.review-tooltip-transition-exit {
  .permalink-tooltip {
    transform: scale(1);
    opacity: 1;
    transition: transform 300ms ease-in-out, opacity 300ms linear;
  }
}
.review-tooltip-transition-exit-active {
  .permalink-tooltip {
    transform: scale(0.3);
    opacity: 0;
  }
}

.metadata-container-transition-enter {
  transform: scaleX(0);
}

.metadata-container-transition-enter-active {
  transform: scaleX(1);
  transition: transform 300ms ease-in-out;
}

.metadata-container-transition-exit {
  transform: scaleX(1);
}

.metadata-container-transition-exit-active {
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}
