@import 'partials/shadows';
@import 'partials/variables';

.map__container {
  height: 100%;
  min-height: 400px;

  .map {
    box-sizing: border-box;
    position: relative;
  }
  .map-selectors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;

    position: absolute;

    top: 140px;
    left: 110px;
    z-index: 101;

    .region-set-selector-wrapper {
      position: relative;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      z-index: 1010;
    }

    .map-selector {
      user-select: none;

      width: auto;
      min-width: 150px;
      max-width: 250px;

      &.region-set-selector {
        width: 200px;
      }

      &:first-child {
        z-index: 1010;
      }
      &:not(:first-child) {
        z-index: 1009;
      }
      .ipcc-select__label {
        color: $primary-color;
        text-shadow: 0.5px 0.5px 0.5px white;
        font-size: 0.8em;
        font-weight: 400;
        padding-bottom: 0px;
      }
    }
  }

  &:not(.mirroring) {
    .map > .ol-viewport {
      margin-top: 42px;
    }
  }

  .ol-full-screen.ol-unselectable.ol-control {
    display: none;
    pointer-events: none;
    user-select: none;
  }

  .ipcc-atlas__loading-indicator {
    width: 50px;
    position: absolute;
    left: calc(50% - 25px);
    bottom: calc(50% - 25px);
    z-index: 1001;
  }

  .map__container__options {
    display: flex;

    position: absolute;
    top: 85px;
    z-index: 1007;
    width: calc(100% - 80px);

    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    box-shadow: 0 4px 2px -2px rgba(128, 128, 128, 0.435);
  }

  .option__dropdown-box {
    .option__dropdown-content {
      backdrop-filter: blur(1em);
    }

    &:hover {
      background-color: $primary-color-accent;
      color: $primary-color;
      svg > path {
        transition: fill 300ms linear;
        fill: $primary-color;
      }
    }
  }

  .feature-info-overlay {
    position: absolute;

    background-color: $primary-color;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    bottom: 12px;
    left: -50px;
    min-width: 300px;
    &:after,
    &:before {
      top: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-top-color: rgba($primary-color, 0.8);
      border-width: 10px;
      left: 48px;
      margin-left: -10px;
    }
    &:before {
      border-top-color: rgba($primary-color, 0.8);
      border-width: 11px;
      left: 48px;
      margin-left: -11px;
    }
  }

  .region-set-select {
    max-width: 100px;
  }

  .expandable-button__container {
    position: absolute;
    z-index: 1000;
    top: 120px;

    // Borrar
    display: none;

    .expandable-button-child__container {
      margin-bottom: 15px !important;
      .projection-option {
        // border: 2px solid var(--primary-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: none;
        padding: 8px;
        border-radius: 35px;
        color: $primary-color-accent;
        background-color: $primary-color;

        outline: none;

        transition: color 0.3s ease, background-color 0.3s ease,
          box-shadow 0.3s ease;

        cursor: pointer;

        svg {
          padding-right: 0.5em;
          path {
            transition: fill 300ms ease;
          }
        }
        &:hover {
          // @include shadow-4dp();
          background-color: $primary-color-accent;
          color: $primary-color;

          svg path {
            fill: $primary-color;
          }
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  /*
  *  COMPARE MAPS
  */

  &.mirroring {
    box-sizing: border-box;

    .ipcc-controls__container {
      right: -34px;
      top: 40px;
      z-index: 1001;
      .ipcc-base-control {
        box-sizing: border-box;
        border: 3px solid $primary-color;
        &:hover {
          box-shadow: none;
        }
      }
      .projection-control {
        order: 10;
      }
    }

    .map-selectors-container {
      left: 100px;
    }

    .ipcc-atlas__loading-indicator {
      left: 60px;
      bottom: 20px;
    }

    .ipcc-map__caption {
      width: calc(50% - 50px);
      max-width: calc(50% - 50px);
      top: 95px;
      bottom: unset !important;
      font-size: 0.9em;

      .map__caption-wrapper {
        color: $primary-color;
      }
    }

    &.primary {
      border-right: 3px solid $primary-color;
    }
    .map__container__options {
      padding: 45px 0px;
      box-sizing: border-box;
      box-shadow: none;
      flex-direction: column;
      width: auto;
      height: calc(100% - 40px - 85px);

      .option__dropdown-box {
        min-width: 45px;
        margin: 0px;
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        border: none;

        .option__dropdown-header {
          width: 45px;
          height: 100%;
          padding: 1em 0px;
          // border-right: 5px solid $primary-color;
          .option__image-wrapper {
            width: 20px;
            padding-left: 2px;
          }
          .option__title--wrapper {
            display: none;
          }
          .option-arrow {
            transform: rotate(-90deg);
          }
        }

        .dropdown__body {
          scroll-behavior: smooth;
          left: 45px;
          /* width: auto; */
          width: calc(50vw - 110px);
          max-width: 400px;
          min-height: calc(100% - 90px);
          bottom: 45px;
          height: calc(100% - 90px);
          overflow-y: auto;

          .option__dropdown-content {
            border-top: none;

            .options__body {
              flex-direction: column;
              &.value-period {
                flex-direction: column;
                flex-wrap: nowrap;
                .value-period-row {
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  min-width: 100%;
                }
                .options__body__list-container {
                  .magnitude-radio-list {
                    flex-direction: column;
                    justify-content: space-evenly;
                    width: 100%;
                    .radio-list-selector__body {
                      display: flex;
                      flex-direction: column;
                      .radio-list-selector__radio {
                        margin: none;
                      }
                    }
                  }
                }
              }
            }

            .options__body__list-container {
              display: flex;
              flex-direction: column;
              width: auto;
              overflow-y: auto;

              .radio-list-selector__body {
                justify-content: center;
                align-content: center;
                .radio-list-selector__radio {
                  margin: auto;
                }
              }
            }
          }
        }
        &.last {
          margin-bottom: 0px;

          .dropdown__body {
            max-height: 331px;
            min-height: unset;
            bottom: 45px;
          }
        }

        &:hover {
          .option__dropdown-header {
            border-bottom: none;
            border-right: 5px solid $primary-color;
          }
          .option-arrow {
            transform: rotate(-270deg);
          }
        }
      }
    }

    .map {
      padding: 45px 22px 45px 45px;

      &:-webkit-full-screen {
        padding: 0px;
      }
      &:-ms-fullscreen {
        padding: 0px;
      }
      &:fullscreen {
        padding: 0px;
      }

      .ipcc-json-legend-container {
        &.left {
          bottom: 25px;
          right: 22px;
          left: unset;

          .legend-units {
            text-align: right;
          }

          .mean__tooltip {
            display: flex;
            justify-content: flex-end;
            width: 60px;
            padding-left: 0px;
            padding-right: 6px;
            transform: translateX(-20px) translateY(8px);
          }

          .mean__tooltip.first {
            transform: translateX(-20px) translateY(-8px);
          }

          .resize-legend {
            float: right;
          }

          .max-min__container {
            transform: scale(1.5) translateX(-48px) !important;
          }

          .axis-svg {
            right: 30px;
            left: unset;
          }

          .color-indicator {
            left: unset;
            right: 0px;
          }

          .hatching-segments-container {
            position: relative;
            left: 16px;
          }

          .legend-item__container {
            flex-direction: row-reverse;
          }

          .min,
          .max {
            &:after {
              right: -17px;
              left: unset;
              border-left: 18px solid $primary-color;
              border-right: none;
            }
          }
        }

        &.right {
          bottom: 25px;
          left: 22px;
          right: unset;

          .legend .axis-svg {
            left: 20px;
          }
        }
      }
    }

    &.secondary {
      border-left: 3px solid $primary-color;
      .map__container__options {
        right: 40px;

        .option__dropdown-box {
          &:hover {
            .option__dropdown-header {
              border-left: 5px solid $primary-color;
              border-right: none;
            }
          }

          .option__dropdown-header {
            // border-left: 5px solid $primary-color;
            border-right: none;

            .option__image-wrapper {
              order: 2;
              width: 20px;
              padding-right: 6px;
            }

            .option-arrow {
              order: 1;
              transform: rotate(90deg);
            }
          }
          .dropdown__body {
            right: 45px;
            left: unset;
            .option__dropdown-content {
              right: 85px;
              left: unset;
            }
          }
          &:hover {
            .option-arrow {
              transform: rotate(270deg) !important;
            }
          }
        }
      }

      .map {
        padding: 45px 45px 45px 22px;

        .ipcc-atlas__loading-indicator {
          left: unset;
          right: 60px;
        }
      }
    }
  }

  /*
  * END COMPARE MAPS
  */
}
