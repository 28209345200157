@import 'partials/breakpoints';
@import 'partials/variables';
@import 'partials/shadows';

@mixin responsive {
  flex-basis: 25%;
  margin: 0px;

  .option__image-wrapper {
    width: unset;
    padding: 0;
  }

  .option__title--wrapper {
    display: none;
  }

  .dropdown__body {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    left: 0;

    .options__body {
      flex-direction: column !important;
      flex-wrap: nowrap;
      overflow-y: auto;
      width: 100%;
      align-items: center;
      justify-content: center;

      &.value-period {
        flex-direction: column;
        flex-wrap: nowrap;
        .value-period-row {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          min-width: 100%;
        }
        .options__body__list-container {
          .magnitude-radio-list {
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            border-bottom: none;

            .radio-list-selector__body {
              display: flex;
              flex-direction: column;
              .radio-list-selector__radio {
                margin: 0px 2em;
              }
            }
          }
        }
      }
    }
  }

  .options__body__list-container {
    width: 100%;
  }
}

@include respond-to(small) {
  .option__dropdown-box {
    @include responsive();
  }
}

@include respond-to(large) {
  .option__dropdown-box.mobile {
    @include responsive();
  }
}

.option__dropdown-box {
  flex-grow: 1;
  background-color: $primary-color-accent;
  color: $primary-color;

  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;

  border-right: 2px solid rgba(128, 128, 128, 0.135);

  &.last {
    border-right: none;
  }

  &.open,
  &:focus-within {
    color: $primary-color-accent;
    background-color: rgba($primary-color, 0.8);
    .option__dropdown-header {
      color: $primary-color-accent;
      background-color: rgba($primary-color, 0.8);

      .option__image-wrapper {
        svg path {
          fill: $primary-color-accent;
        }
      }
    }
    .option-arrow {
      transform: rotate(180deg);
      path {
        fill: $primary-color-accent;
      }
    }
  }
}

.option__dropdown-header {
  box-sizing: border-box;
  transition: color 300ms ease, background-color 300ms ease, border 300ms ease;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px;
  width: 100%;
  cursor: pointer;
  user-select: none;

  .option__image-wrapper {
    height: 25px;
    width: 45px;
    padding-right: 2px;

    svg path {
      transition: fill 300ms ease;
      fill: $primary-color;
    }
  }

  .option__title--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 0px;

    .option__title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1em;
    }
    .option__subtitle {
      font-size: 0.8em;
    }
  }

  .option-arrow {
    path {
      fill: $primary-color;
    }

    transition: transform 150ms linear;
  }
}

.dropdown__body {
  display: block;
  border-top: none;
  z-index: 10001;
  max-height: calc(100vh - 40px - 85px - 41px);
  max-width: calc(100vw - 80px);
  overflow: auto;

  .option__dropdown-content {
    background-color: rgba($primary-color, 0.8);
    color: $primary-color-accent;
    border-top: 1px solid $primary-color-accent;

    .options__body {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 1em;

      .options__body__list-container {
        width: auto;
        padding: 1.5em;
      }

      &.value-period {
        flex-direction: column;
        flex-wrap: nowrap;
        .value-period-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          min-width: 100%;
        }
        .options__body__list-container {
          .magnitude-radio-list {
            flex-direction: row;
            justify-content: space-evenly;
            width: 100%;

            border-bottom: 1px solid white;

            margin-bottom: -1em;

            .radio-list-selector__body {
              display: flex;
              flex-direction: row;
              .radio-list-selector__radio {
                margin: 0px 2em;
              }
            }
          }
        }
      }
    }
  }
}
